@charset "UTF-8";
/**
 * @author sesa 534419 - Ashutosh Singh
 * @email ashutosh.singh1@non.se.com
 * @create date 2019-04-27 18:09:35
 * @modify date 2021-08-27 13:00:20
 * @desc This is the root style for entire application.
 * Please DO NOT CHANGE IN THIS FILE UNLESS REQUIRED, YOU CAN CHECK WITH YOUR CODE REVIEWER
 * FOR YOUR COCERN REGARDING CAHNGING IN THIS FILE 
 */
/** Font common accross the application **/
@font-face {
  font-family: "se-icon";
  src: url("~@se/icons/fonts/se-icon.woff2") format("woff2"), url("~@se/icons/fonts/se-icon.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class*=se-icon] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "se-icon";
  font-style: normal;
  line-height: 1;
  position: relative;
  top: 1px;
  display: inline-block;
  font-size: 24px;
  font-weight: normal;
  font-variant: normal;
  text-decoration: inherit;
  font-stretch: normal;
  text-transform: none;
  font-size: inherit;
  text-rendering: auto;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Better Font Rendering */
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
}

.se-icon-2d:before {
  content: "";
}

.se-icon-3d_composite:before {
  content: "";
}

.se-icon-3d_convertor:before {
  content: "";
}

.se-icon-3d_view_360:before {
  content: "";
}

.se-icon-3d_viewer:before {
  content: "";
}

.se-icon-3d:before {
  content: "";
}

.se-icon-about:before {
  content: "";
}

.se-icon-accessibility:before {
  content: "";
}

.se-icon-accessories:before {
  content: "";
}

.se-icon-ack_mounted_pdu:before {
  content: "";
}

.se-icon-action_add_flat:before {
  content: "";
}

.se-icon-action_add_stroke:before {
  content: "";
}

.se-icon-action_align_bottom:before {
  content: "";
}

.se-icon-action_align_horizontal_center:before {
  content: "";
}

.se-icon-action_align_horizontal_left:before {
  content: "";
}

.se-icon-action_align_horizontal_right:before {
  content: "";
}

.se-icon-action_align_middle:before {
  content: "";
}

.se-icon-action_align_to_grid:before {
  content: "";
}

.se-icon-action_align_top:before {
  content: "";
}

.se-icon-action_attach:before {
  content: "";
}

.se-icon-action_ban:before {
  content: "";
}

.se-icon-action_bold:before {
  content: "";
}

.se-icon-action_close:before {
  content: "";
}

.se-icon-action_collapse_all:before {
  content: "";
}

.se-icon-action_compare:before {
  content: "";
}

.se-icon-action_cursor:before {
  content: "";
}

.se-icon-action_cut:before {
  content: "";
}

.se-icon-action_datasheet:before {
  content: "";
}

.se-icon-action_delete_cross:before {
  content: "";
}

.se-icon-action_delete:before {
  content: "";
}

.se-icon-action_distribute_horizontal_center:before {
  content: "";
}

.se-icon-action_distribute_horizontal_left:before {
  content: "";
}

.se-icon-action_distribute_horizontal_right:before {
  content: "";
}

.se-icon-action_distribute_vertical_bottom:before {
  content: "";
}

.se-icon-action_distribute_vertical_center:before {
  content: "";
}

.se-icon-action_distribute_vertical_top:before {
  content: "";
}

.se-icon-action_doc_add:before {
  content: "";
}

.se-icon-action_doc_export_stroke:before {
  content: "";
}

.se-icon-action_doc_export:before {
  content: "";
}

.se-icon-action_doc_import_stroke:before {
  content: "";
}

.se-icon-action_doc_import:before {
  content: "";
}

.se-icon-action_doc_new:before {
  content: "";
}

.se-icon-action_doc_term_of_use:before {
  content: "";
}

.se-icon-action_download_stroke:before {
  content: "";
}

.se-icon-action_download:before {
  content: "";
}

.se-icon-action_duplicate:before {
  content: "";
}

.se-icon-action_editor:before {
  content: "";
}

.se-icon-action_erase:before {
  content: "";
}

.se-icon-action_expand_all:before {
  content: "";
}

.se-icon-action_favourite_library_filled:before {
  content: "";
}

.se-icon-action_favourite_library:before {
  content: "";
}

.se-icon-action_filter:before {
  content: "";
}

.se-icon-action_generate_labels:before {
  content: "";
}

.se-icon-action_generate_symbols:before {
  content: "";
}

.se-icon-action_group:before {
  content: "";
}

.se-icon-action_italic:before {
  content: "";
}

.se-icon-action_layer_back:before {
  content: "";
}

.se-icon-action_layer_backward:before {
  content: "";
}

.se-icon-action_layer_forward:before {
  content: "";
}

.se-icon-action_layer_front:before {
  content: "";
}

.se-icon-action_lock_off_q1:before {
  content: "";
}

.se-icon-action_lock_off:before {
  content: "";
}

.se-icon-action_lock_on_q1:before {
  content: "";
}

.se-icon-action_lock_on:before {
  content: "";
}

.se-icon-action_login:before {
  content: "";
}

.se-icon-action_logout:before {
  content: "";
}

.se-icon-action_new_window:before {
  content: "";
}

.se-icon-action_new_with_wizzard:before {
  content: "";
}

.se-icon-action_next:before {
  content: "";
}

.se-icon-action_notification_default:before {
  content: "";
}

.se-icon-action_open_project:before {
  content: "";
}

.se-icon-action_open3:before {
  content: "";
}

.se-icon-action_paste:before {
  content: "";
}

.se-icon-action_pdf_report:before {
  content: "";
}

.se-icon-action_picture:before {
  content: "";
}

.se-icon-action_pin:before {
  content: "";
}

.se-icon-action_previous:before {
  content: "";
}

.se-icon-action_print_preview:before {
  content: "";
}

.se-icon-action_print:before {
  content: "";
}

.se-icon-action_redo:before {
  content: "";
}

.se-icon-action_remove_flat:before {
  content: "";
}

.se-icon-action_remove_stroke:before {
  content: "";
}

.se-icon-action_report:before {
  content: "";
}

.se-icon-action_report2:before {
  content: "";
}

.se-icon-action_resize_xandy:before {
  content: "";
}

.se-icon-action_resize:before {
  content: "";
}

.se-icon-action_rotate_left:before {
  content: "";
}

.se-icon-action_rotate_right:before {
  content: "";
}

.se-icon-action_ruler:before {
  content: "";
}

.se-icon-action_save:before {
  content: "";
}

.se-icon-action_saveas:before {
  content: "";
}

.se-icon-action_scan_qrcode:before {
  content: "";
}

.se-icon-action_search_project:before {
  content: "";
}

.se-icon-action_search_stroke:before {
  content: "";
}

.se-icon-action_search:before {
  content: "";
}

.se-icon-action_settings1:before {
  content: "";
}

.se-icon-action_settings2:before {
  content: "";
}

.se-icon-action_shadow:before {
  content: "";
}

.se-icon-action_share:before {
  content: "";
}

.se-icon-action_sort:before {
  content: "";
}

.se-icon-action_test:before {
  content: "";
}

.se-icon-action_text_shadow:before {
  content: "";
}

.se-icon-action_underline:before {
  content: "";
}

.se-icon-action_undo:before {
  content: "";
}

.se-icon-action_ungroup:before {
  content: "";
}

.se-icon-action_upgrade:before {
  content: "";
}

.se-icon-action_upload_stroke:before {
  content: "";
}

.se-icon-action_upload:before {
  content: "";
}

.se-icon-action_user_library:before {
  content: "";
}

.se-icon-action_write_from_device:before {
  content: "";
}

.se-icon-action_write_from_mobile:before {
  content: "";
}

.se-icon-action_write_from_project:before {
  content: "";
}

.se-icon-action_write_from_system:before {
  content: "";
}

.se-icon-action_write_to_device:before {
  content: "";
}

.se-icon-action_write_to_mobile:before {
  content: "";
}

.se-icon-action_write_to_project:before {
  content: "";
}

.se-icon-action_write_to_system:before {
  content: "";
}

.se-icon-action_zoom_100_stroke:before {
  content: "";
}

.se-icon-action_zoom_100:before {
  content: "";
}

.se-icon-action_zoom_minus_stroke:before {
  content: "";
}

.se-icon-action_zoom_minus:before {
  content: "";
}

.se-icon-action_zoom_plus_stroke:before {
  content: "";
}

.se-icon-action_zoom_plus:before {
  content: "";
}

.se-icon-action_zoom_selected_area_stroke:before {
  content: "";
}

.se-icon-activity_feed:before {
  content: "";
}

.se-icon-address_book:before {
  content: "";
}

.se-icon-air_quality:before {
  content: "";
}

.se-icon-airconditioner_hot_heating:before {
  content: "";
}

.se-icon-airconditioner:before {
  content: "";
}

.se-icon-airconditioning_cold_cooling:before {
  content: "";
}

.se-icon-airconditioning:before {
  content: "";
}

.se-icon-alarm_clock:before {
  content: "";
}

.se-icon-analytics_stroke:before {
  content: "";
}

.se-icon-analytics:before {
  content: "";
}

.se-icon-architect:before {
  content: "";
}

.se-icon-archive:before {
  content: "";
}

.se-icon-arrow_above:before {
  content: "";
}

.se-icon-arrow_below:before {
  content: "";
}

.se-icon-arrow_long_up:before {
  content: "";
}

.se-icon-arrow_short_up:before {
  content: "";
}

.se-icon-arrow1_down_right:before {
  content: "";
}

.se-icon-arrow1_left:before {
  content: "";
}

.se-icon-arrow1_pointer_down:before {
  content: "";
}

.se-icon-arrow1_pointer_up:before {
  content: "";
}

.se-icon-arrow1_right:before {
  content: "";
}

.se-icon-arrow1_rotation:before {
  content: "";
}

.se-icon-arrow1_up_right:before {
  content: "";
}

.se-icon-arrow2_default:before {
  content: "";
}

.se-icon-arrow2_down:before {
  content: "";
}

.se-icon-arrow2_left:before {
  content: "";
}

.se-icon-arrow2_right:before {
  content: "";
}

.se-icon-arrow2_rotation:before {
  content: "";
}

.se-icon-arrow2_up:before {
  content: "";
}

.se-icon-arrow3_down:before {
  content: "";
}

.se-icon-arrow3_up:before {
  content: "";
}

.se-icon-arrow4_down:before {
  content: "";
}

.se-icon-arrow4_top:before {
  content: "";
}

.se-icon-arrow5_edge:before {
  content: "";
}

.se-icon-arrow5_step:before {
  content: "";
}

.se-icon-asset_optimisation:before {
  content: "";
}

.se-icon-battery1_empty:before {
  content: "";
}

.se-icon-battery1_full:before {
  content: "";
}

.se-icon-battery1_general_left:before {
  content: "";
}

.se-icon-battery1_half:before {
  content: "";
}

.se-icon-battery1_low:before {
  content: "";
}

.se-icon-battery1_positioning:before {
  content: "";
}

.se-icon-battery1_test:before {
  content: "";
}

.se-icon-battery2_alternating_current:before {
  content: "";
}

.se-icon-battery2_charging:before {
  content: "";
}

.se-icon-battery2_check:before {
  content: "";
}

.se-icon-battery2_empty1:before {
  content: "";
}

.se-icon-battery2_empty2:before {
  content: "";
}

.se-icon-battery2_full:before {
  content: "";
}

.se-icon-battery2_general:before {
  content: "";
}

.se-icon-battery2_half:before {
  content: "";
}

.se-icon-battery2_lithium:before {
  content: "";
}

.se-icon-battery2_low:before {
  content: "";
}

.se-icon-battery2_ratio:before {
  content: "";
}

.se-icon-battery3_general:before {
  content: "";
}

.se-icon-bbq:before {
  content: "";
}

.se-icon-beaker:before {
  content: "";
}

.se-icon-binoculars:before {
  content: "";
}

.se-icon-body_ear:before {
  content: "";
}

.se-icon-body_eye:before {
  content: "";
}

.se-icon-body_hand1:before {
  content: "";
}

.se-icon-body_handshake:before {
  content: "";
}

.se-icon-body_mouth:before {
  content: "";
}

.se-icon-book:before {
  content: "";
}

.se-icon-box1:before {
  content: "";
}

.se-icon-box2:before {
  content: "";
}

.se-icon-brightness_contrast:before {
  content: "";
}

.se-icon-brochure:before {
  content: "";
}

.se-icon-building_amphitheater:before {
  content: "";
}

.se-icon-building_badge_position:before {
  content: "";
}

.se-icon-building_flat:before {
  content: "";
}

.se-icon-building_heat_recovery:before {
  content: "";
}

.se-icon-building_occupants:before {
  content: "";
}

.se-icon-building_reading_room:before {
  content: "";
}

.se-icon-building_restaurant:before {
  content: "";
}

.se-icon-building_sprinkler:before {
  content: "";
}

.se-icon-building_surface:before {
  content: "";
}

.se-icon-building_surface1:before {
  content: "";
}

.se-icon-building_swimmingpool:before {
  content: "";
}

.se-icon-building_toilets:before {
  content: "";
}

.se-icon-building:before {
  content: "";
}

.se-icon-burger_menu:before {
  content: "";
}

.se-icon-burglar_alarm:before {
  content: "";
}

.se-icon-buy_digital_modules:before {
  content: "";
}

.se-icon-cable_manager:before {
  content: "";
}

.se-icon-calculation_annotate_power_balance:before {
  content: "";
}

.se-icon-calculation_annotate_result:before {
  content: "";
}

.se-icon-calculation_annotate:before {
  content: "";
}

.se-icon-calculation_launch_powerbalance:before {
  content: "";
}

.se-icon-calculation_result2:before {
  content: "";
}

.se-icon-calculation_results:before {
  content: "";
}

.se-icon-calculator:before {
  content: "";
}

.se-icon-calendar:before {
  content: "";
}

.se-icon-calender2:before {
  content: "";
}

.se-icon-carboy:before {
  content: "";
}

.se-icon-ceiling_fan:before {
  content: "";
}

.se-icon-central_unit:before {
  content: "";
}

.se-icon-child:before {
  content: "";
}

.se-icon-circuit_breaker:before {
  content: "";
}

.se-icon-cloud_download:before {
  content: "";
}

.se-icon-cloud_sync:before {
  content: "";
}

.se-icon-cloud_upload_stroke:before {
  content: "";
}

.se-icon-cloud_upload:before {
  content: "";
}

.se-icon-clutch:before {
  content: "";
}

.se-icon-co2_1:before {
  content: "";
}

.se-icon-co2_2:before {
  content: "";
}

.se-icon-color_palette:before {
  content: "";
}

.se-icon-comfort_boost:before {
  content: "";
}

.se-icon-comfort:before {
  content: "";
}

.se-icon-communication_bubbles1:before {
  content: "";
}

.se-icon-communication_bubbles2:before {
  content: "";
}

.se-icon-communication_bubbles3:before {
  content: "";
}

.se-icon-communication_view:before {
  content: "";
}

.se-icon-compact:before {
  content: "";
}

.se-icon-connect_device_directly:before {
  content: "";
}

.se-icon-connection_bluetooth:before {
  content: "";
}

.se-icon-connection_cloud_connection_error:before {
  content: "";
}

.se-icon-connection_cloud_connection:before {
  content: "";
}

.se-icon-connection_cloud_synchronize_device:before {
  content: "";
}

.se-icon-connection_direct:before {
  content: "";
}

.se-icon-connection_ethernet:before {
  content: "";
}

.se-icon-connection_gsm:before {
  content: "";
}

.se-icon-connection_hdmi:before {
  content: "";
}

.se-icon-connection_router:before {
  content: "";
}

.se-icon-connection_satellite:before {
  content: "";
}

.se-icon-connection_sdcard:before {
  content: "";
}

.se-icon-connection_serial_port:before {
  content: "";
}

.se-icon-connection_usb_power:before {
  content: "";
}

.se-icon-connection_usb:before {
  content: "";
}

.se-icon-connection_wireless1:before {
  content: "";
}

.se-icon-connection_wireless2:before {
  content: "";
}

.se-icon-connection_zigbee:before {
  content: "";
}

.se-icon-consulting_service:before {
  content: "";
}

.se-icon-consumer:before {
  content: "";
}

.se-icon-continuity:before {
  content: "";
}

.se-icon-control_local:before {
  content: "";
}

.se-icon-control_remote:before {
  content: "";
}

.se-icon-cooking_plate:before {
  content: "";
}

.se-icon-country_china:before {
  content: "";
}

.se-icon-country_france:before {
  content: "";
}

.se-icon-country_spain:before {
  content: "";
}

.se-icon-cover_switch:before {
  content: "";
}

.se-icon-create_quotation:before {
  content: "";
}

.se-icon-crown:before {
  content: "";
}

.se-icon-cup:before {
  content: "";
}

.se-icon-cybersecurity_antivirus:before {
  content: "";
}

.se-icon-cybersecurity_generic:before {
  content: "";
}

.se-icon-cybersecurity_hacker:before {
  content: "";
}

.se-icon-cybersecurity_patch_management:before {
  content: "";
}

.se-icon-cybersecurity_phising:before {
  content: "";
}

.se-icon-cybersecurity_training:before {
  content: "";
}

.se-icon-cybersecurity_virus:before {
  content: "";
}

.se-icon-danger_hotsurface_stroke:before {
  content: "";
}

.se-icon-danger_hotsurface:before {
  content: "";
}

.se-icon-dashboard:before {
  content: "";
}

.se-icon-data_refresh_stroke:before {
  content: "";
}

.se-icon-data_refresh:before {
  content: "";
}

.se-icon-data_synchronizing:before {
  content: "";
}

.se-icon-data_transfer_in:before {
  content: "";
}

.se-icon-data_transfer_internal:before {
  content: "";
}

.se-icon-data_transfer_out:before {
  content: "";
}

.se-icon-data_transfer:before {
  content: "";
}

.se-icon-daynight_mode_day:before {
  content: "";
}

.se-icon-daynight_mode_night:before {
  content: "";
}

.se-icon-daynight_mode:before {
  content: "";
}

.se-icon-depth:before {
  content: "";
}

.se-icon-device_breaker:before {
  content: "";
}

.se-icon-device_checkup:before {
  content: "";
}

.se-icon-device_custom:before {
  content: "";
}

.se-icon-device_not_commissioned:before {
  content: "";
}

.se-icon-diagnostic:before {
  content: "";
}

.se-icon-diesel_generator:before {
  content: "";
}

.se-icon-differential_switch:before {
  content: "";
}

.se-icon-digital_view:before {
  content: "";
}

.se-icon-dimmer:before {
  content: "";
}

.se-icon-door_closed:before {
  content: "";
}

.se-icon-door_open:before {
  content: "";
}

.se-icon-doorway:before {
  content: "";
}

.se-icon-dropper:before {
  content: "";
}

.se-icon-earth_america:before {
  content: "";
}

.se-icon-earth_arrow:before {
  content: "";
}

.se-icon-earth_europe_africa:before {
  content: "";
}

.se-icon-earth_pacific:before {
  content: "";
}

.se-icon-ecommerce:before {
  content: "";
}

.se-icon-ecomode_off:before {
  content: "";
}

.se-icon-ecomode_on:before {
  content: "";
}

.se-icon-electric_danger:before {
  content: "";
}

.se-icon-electric_hazard:before {
  content: "";
}

.se-icon-electric_heater:before {
  content: "";
}

.se-icon-electric_overload1:before {
  content: "";
}

.se-icon-electric_overload2:before {
  content: "";
}

.se-icon-electric_vehicle_terminal:before {
  content: "";
}

.se-icon-electrical_danger:before {
  content: "";
}

.se-icon-electrical_fault:before {
  content: "";
}

.se-icon-electrical_fire_prevention:before {
  content: "";
}

.se-icon-electrical_plug:before {
  content: "";
}

.se-icon-electrical_plug2:before {
  content: "";
}

.se-icon-electrical_plug3:before {
  content: "";
}

.se-icon-electrical_plug4:before {
  content: "";
}

.se-icon-electrical_scooter:before {
  content: "";
}

.se-icon-electrical_vehicle1:before {
  content: "";
}

.se-icon-electrical_vehicle2:before {
  content: "";
}

.se-icon-electricity_automatic_voltage_resolution:before {
  content: "";
}

.se-icon-electricity_busbar:before {
  content: "";
}

.se-icon-electricity_bypass:before {
  content: "";
}

.se-icon-electricity_capacitor_general_symbol:before {
  content: "";
}

.se-icon-electricity_circuitbreaker_trip:before {
  content: "";
}

.se-icon-electricity_classII_equipment:before {
  content: "";
}

.se-icon-electricity_critical_power:before {
  content: "";
}

.se-icon-electricity_current_alternating_view:before {
  content: "";
}

.se-icon-electricity_current_alternating:before {
  content: "";
}

.se-icon-electricity_current_bothdirect_alternating:before {
  content: "";
}

.se-icon-electricity_current_direct:before {
  content: "";
}

.se-icon-electricity_current_rectified:before {
  content: "";
}

.se-icon-electricity_dc_ac_converter:before {
  content: "";
}

.se-icon-electricity_discharge_protect:before {
  content: "";
}

.se-icon-electricity_fonctionnal_earthing:before {
  content: "";
}

.se-icon-electricity_fuse10a:before {
  content: "";
}

.se-icon-electricity_ground_fault_protection:before {
  content: "";
}

.se-icon-electricity_ground_noiseless:before {
  content: "";
}

.se-icon-electricity_ground_protective:before {
  content: "";
}

.se-icon-electricity_ground:before {
  content: "";
}

.se-icon-electricity_ohms:before {
  content: "";
}

.se-icon-electricity_polarity_negative_minus:before {
  content: "";
}

.se-icon-electricity_polarity_positive_plus:before {
  content: "";
}

.se-icon-electricity_protection_adjustment:before {
  content: "";
}

.se-icon-electricity_protection_earth_overcurrent:before {
  content: "";
}

.se-icon-electricity_protection_IP21Dchild:before {
  content: "";
}

.se-icon-electricity_protection_lightning:before {
  content: "";
}

.se-icon-electricity_protection_MVrelay:before {
  content: "";
}

.se-icon-electricity_protection_of_persons:before {
  content: "";
}

.se-icon-electricity_protection_phase_overcurrent:before {
  content: "";
}

.se-icon-electricity_protection_thermal_overload:before {
  content: "";
}

.se-icon-electricity_pulse_general:before {
  content: "";
}

.se-icon-electricity_socket_mennekes:before {
  content: "";
}

.se-icon-electricity_socket_shouco:before {
  content: "";
}

.se-icon-electricity_socket_type3:before {
  content: "";
}

.se-icon-electricity_switch_silence:before {
  content: "";
}

.se-icon-electricity_switch:before {
  content: "";
}

.se-icon-electricity_switchboard_hv:before {
  content: "";
}

.se-icon-electricity_switchboard_lv:before {
  content: "";
}

.se-icon-electricity_switchboard:before {
  content: "";
}

.se-icon-electricity_transformer_230V12V:before {
  content: "";
}

.se-icon-electricity_transformer:before {
  content: "";
}

.se-icon-electrostatic_devices:before {
  content: "";
}

.se-icon-elevator_closed:before {
  content: "";
}

.se-icon-empty_set:before {
  content: "";
}

.se-icon-energy_efficiency:before {
  content: "";
}

.se-icon-energy:before {
  content: "";
}

.se-icon-ethernet_switch:before {
  content: "";
}

.se-icon-event:before {
  content: "";
}

.se-icon-existing_project:before {
  content: "";
}

.se-icon-exit_presentation:before {
  content: "";
}

.se-icon-eye_dont_show:before {
  content: "";
}

.se-icon-face_happy:before {
  content: "";
}

.se-icon-face_meh:before {
  content: "";
}

.se-icon-face_unhappy:before {
  content: "";
}

.se-icon-favourite_insert:before {
  content: "";
}

.se-icon-favourites_addto_plain:before {
  content: "";
}

.se-icon-favourites_addto:before {
  content: "";
}

.se-icon-fax:before {
  content: "";
}

.se-icon-feeder:before {
  content: "";
}

.se-icon-file_bim:before {
  content: "";
}

.se-icon-file_bom:before {
  content: "";
}

.se-icon-file_cad:before {
  content: "";
}

.se-icon-file_csv:before {
  content: "";
}

.se-icon-file_docx:before {
  content: "";
}

.se-icon-file_dwg:before {
  content: "";
}

.se-icon-file_eq:before {
  content: "";
}

.se-icon-file_jpg:before {
  content: "";
}

.se-icon-file_placeholder:before {
  content: "";
}

.se-icon-file_png:before {
  content: "";
}

.se-icon-file_ppt:before {
  content: "";
}

.se-icon-file_rfa:before {
  content: "";
}

.se-icon-file_xlsx:before {
  content: "";
}

.se-icon-file_xml:before {
  content: "";
}

.se-icon-fire_alarm:before {
  content: "";
}

.se-icon-fire_extinguisher:before {
  content: "";
}

.se-icon-fire:before {
  content: "";
}

.se-icon-firmware_upgrade:before {
  content: "";
}

.se-icon-first_aid:before {
  content: "";
}

.se-icon-fit_to_page:before {
  content: "";
}

.se-icon-flag_1:before {
  content: "";
}

.se-icon-flag_arrival:before {
  content: "";
}

.se-icon-flag_language:before {
  content: "";
}

.se-icon-flexibility:before {
  content: "";
}

.se-icon-fm_radio:before {
  content: "";
}

.se-icon-folder_add:before {
  content: "";
}

.se-icon-folder_delete:before {
  content: "";
}

.se-icon-folder_goto_parent:before {
  content: "";
}

.se-icon-folder_search:before {
  content: "";
}

.se-icon-folder:before {
  content: "";
}

.se-icon-frame_chassis:before {
  content: "";
}

.se-icon-furniture_armchair:before {
  content: "";
}

.se-icon-furniture_seat:before {
  content: "";
}

.se-icon-gas_cooler:before {
  content: "";
}

.se-icon-gas_heating:before {
  content: "";
}

.se-icon-gas_station:before {
  content: "";
}

.se-icon-gas:before {
  content: "";
}

.se-icon-general_danger_full:before {
  content: "";
}

.se-icon-general_danger_wired:before {
  content: "";
}

.se-icon-genset_01:before {
  content: "";
}

.se-icon-gesture_pointing:before {
  content: "";
}

.se-icon-gesture_selector:before {
  content: "";
}

.se-icon-gesture_selector2:before {
  content: "";
}

.se-icon-gesture_touch:before {
  content: "";
}

.se-icon-gesture_touch1:before {
  content: "";
}

.se-icon-get_rewards:before {
  content: "";
}

.se-icon-gift:before {
  content: "";
}

.se-icon-graph_barchart:before {
  content: "";
}

.se-icon-graph_down:before {
  content: "";
}

.se-icon-graph_nodata:before {
  content: "";
}

.se-icon-graph_threshold_down:before {
  content: "";
}

.se-icon-graph_threshold_exceed:before {
  content: "";
}

.se-icon-graph_threshold:before {
  content: "";
}

.se-icon-graph_up:before {
  content: "";
}

.se-icon-grid:before {
  content: "";
}

.se-icon-guided_quote:before {
  content: "";
}

.se-icon-h2o_vehicle_terminal:before {
  content: "";
}

.se-icon-head_phone:before {
  content: "";
}

.se-icon-headset:before {
  content: "";
}

.se-icon-heat_exchanger:before {
  content: "";
}

.se-icon-heat_pump:before {
  content: "";
}

.se-icon-height:before {
  content: "";
}

.se-icon-helmet:before {
  content: "";
}

.se-icon-help_readusermanuel_systemfault:before {
  content: "";
}

.se-icon-help_readusermanuel:before {
  content: "";
}

.se-icon-help_wired_flat:before {
  content: "";
}

.se-icon-help:before {
  content: "";
}

.se-icon-hifi:before {
  content: "";
}

.se-icon-history:before {
  content: "";
}

.se-icon-home:before {
  content: "";
}

.se-icon-horn_off:before {
  content: "";
}

.se-icon-horn_on:before {
  content: "";
}

.se-icon-hospital_nurse_call1:before {
  content: "";
}

.se-icon-hospital_nurse_call2:before {
  content: "";
}

.se-icon-hotel_donot_disturb:before {
  content: "";
}

.se-icon-hotel_key_card:before {
  content: "";
}

.se-icon-hotel_please_clean:before {
  content: "";
}

.se-icon-hotel_room_service:before {
  content: "";
}

.se-icon-hotel_solarium:before {
  content: "";
}

.se-icon-house_at_home:before {
  content: "";
}

.se-icon-house_automatic_watering:before {
  content: "";
}

.se-icon-house_bath:before {
  content: "";
}

.se-icon-house_bathroom:before {
  content: "";
}

.se-icon-house_bedroom:before {
  content: "";
}

.se-icon-house_bell:before {
  content: "";
}

.se-icon-house_blind_down:before {
  content: "";
}

.se-icon-house_blind_up:before {
  content: "";
}

.se-icon-house_blind:before {
  content: "";
}

.se-icon-house_blindslat_close:before {
  content: "";
}

.se-icon-house_blindslat_open:before {
  content: "";
}

.se-icon-house_boiler:before {
  content: "";
}

.se-icon-house_chicken_grill:before {
  content: "";
}

.se-icon-house_cooktop:before {
  content: "";
}

.se-icon-house_curtains_close:before {
  content: "";
}

.se-icon-house_curtains_open:before {
  content: "";
}

.se-icon-house_dining_room:before {
  content: "";
}

.se-icon-house_dishwasher:before {
  content: "";
}

.se-icon-house_dressing_room:before {
  content: "";
}

.se-icon-house_floor_heating:before {
  content: "";
}

.se-icon-house_freezer:before {
  content: "";
}

.se-icon-house_frontdoor:before {
  content: "";
}

.se-icon-house_garage_door_down:before {
  content: "";
}

.se-icon-house_garage_door_up:before {
  content: "";
}

.se-icon-house_garage_door:before {
  content: "";
}

.se-icon-house_garage:before {
  content: "";
}

.se-icon-house_garden:before {
  content: "";
}

.se-icon-house_home_locking:before {
  content: "";
}

.se-icon-house_home_unlocking:before {
  content: "";
}

.se-icon-house_in_vacation:before {
  content: "";
}

.se-icon-house_intercom:before {
  content: "";
}

.se-icon-house_kitchen_hood:before {
  content: "";
}

.se-icon-house_kitchen:before {
  content: "";
}

.se-icon-house_lounge_living_room:before {
  content: "";
}

.se-icon-house_metering:before {
  content: "";
}

.se-icon-house_outdoor_socket:before {
  content: "";
}

.se-icon-house_outof_home:before {
  content: "";
}

.se-icon-house_oven_microwaves:before {
  content: "";
}

.se-icon-house_oven:before {
  content: "";
}

.se-icon-house_radiator:before {
  content: "";
}

.se-icon-house_refrigerator:before {
  content: "";
}

.se-icon-house_roller_blind_down:before {
  content: "";
}

.se-icon-house_roller_blind_up:before {
  content: "";
}

.se-icon-house_roller_blind:before {
  content: "";
}

.se-icon-house_sauna:before {
  content: "";
}

.se-icon-house_shaver:before {
  content: "";
}

.se-icon-house_stairs:before {
  content: "";
}

.se-icon-house_swimming_pool:before {
  content: "";
}

.se-icon-house_terrace:before {
  content: "";
}

.se-icon-house_tumble_drier:before {
  content: "";
}

.se-icon-house_washing_machine:before {
  content: "";
}

.se-icon-house_water_heater_electric:before {
  content: "";
}

.se-icon-house_water_heater_solar:before {
  content: "";
}

.se-icon-house_window2:before {
  content: "";
}

.se-icon-housedoor1:before {
  content: "";
}

.se-icon-household:before {
  content: "";
}

.se-icon-hub_connection_error:before {
  content: "";
}

.se-icon-hub_connection_success:before {
  content: "";
}

.se-icon-hub_connection:before {
  content: "";
}

.se-icon-in_transit:before {
  content: "";
}

.se-icon-incomer:before {
  content: "";
}

.se-icon-information_circle:before {
  content: "";
}

.se-icon-information_sheet:before {
  content: "";
}

.se-icon-information_stroke:before {
  content: "";
}

.se-icon-input_output_in:before {
  content: "";
}

.se-icon-input_output_out:before {
  content: "";
}

.se-icon-input_output:before {
  content: "";
}

.se-icon-internet:before {
  content: "";
}

.se-icon-ionizing_radiation:before {
  content: "";
}

.se-icon-it_data_raw:before {
  content: "";
}

.se-icon-it_data_set:before {
  content: "";
}

.se-icon-it_data:before {
  content: "";
}

.se-icon-it_embedded_HMI:before {
  content: "";
}

.se-icon-it_keyboard:before {
  content: "";
}

.se-icon-it_laptop:before {
  content: "";
}

.se-icon-it_pc:before {
  content: "";
}

.se-icon-it_screen:before {
  content: "";
}

.se-icon-it_server:before {
  content: "";
}

.se-icon-it_smartphone:before {
  content: "";
}

.se-icon-it_web_browser:before {
  content: "";
}

.se-icon-key:before {
  content: "";
}

.se-icon-knx:before {
  content: "";
}

.se-icon-ladder_horizontal:before {
  content: "";
}

.se-icon-lamp_ceiling:before {
  content: "";
}

.se-icon-lamp_compact_fluorescent:before {
  content: "";
}

.se-icon-lamp_floor:before {
  content: "";
}

.se-icon-lamp_spotlight:before {
  content: "";
}

.se-icon-launch_device_discovery:before {
  content: "";
}

.se-icon-library:before {
  content: "";
}

.se-icon-light_group_off:before {
  content: "";
}

.se-icon-light_group_on:before {
  content: "";
}

.se-icon-light_half:before {
  content: "";
}

.se-icon-light_off:before {
  content: "";
}

.se-icon-light_on:before {
  content: "";
}

.se-icon-light_tube:before {
  content: "";
}

.se-icon-lighting_lowIntensity:before {
  content: "";
}

.se-icon-lighting_no_blackout:before {
  content: "";
}

.se-icon-lighting_off:before {
  content: "";
}

.se-icon-lighting_test:before {
  content: "";
}

.se-icon-lighting:before {
  content: "";
}

.se-icon-lightning:before {
  content: "";
}

.se-icon-list:before {
  content: "";
}

.se-icon-load_profile:before {
  content: "";
}

.se-icon-load100_percent:before {
  content: "";
}

.se-icon-loading:before {
  content: "";
}

.se-icon-locate_me:before {
  content: "";
}

.se-icon-location_map:before {
  content: "";
}

.se-icon-location:before {
  content: "";
}

.se-icon-logo_android_sdk:before {
  content: "";
}

.se-icon-logo_Ecostruxure:before {
  content: "";
}

.se-icon-logo_Facebook_circle:before {
  content: "";
}

.se-icon-logo_Facebook:before {
  content: "";
}

.se-icon-logo_Google_plus_circle:before {
  content: "";
}

.se-icon-logo_Google_plus:before {
  content: "";
}

.se-icon-logo_Linkedin_circle:before {
  content: "";
}

.se-icon-logo_Linkedin:before {
  content: "";
}

.se-icon-logo_Odnoklassniki_circle:before {
  content: "";
}

.se-icon-logo_Odnoklassniki:before {
  content: "";
}

.se-icon-logo_os_sdk:before {
  content: "";
}

.se-icon-logo_QQ_circle:before {
  content: "";
}

.se-icon-logo_QQ:before {
  content: "";
}

.se-icon-logo_Schneider_Electric_circle:before {
  content: "";
}

.se-icon-logo_Schneider_Electric_logotype:before {
  content: "";
}

.se-icon-logo_Schneider_Electric:before {
  content: "";
}

.se-icon-logo_Twitter_circle:before {
  content: "";
}

.se-icon-logo_Twitter:before {
  content: "";
}

.se-icon-logo_Vkontakte_circle:before {
  content: "";
}

.se-icon-logo_Vkontakte:before {
  content: "";
}

.se-icon-logo_Wechat_circle:before {
  content: "";
}

.se-icon-logo_Wechat:before {
  content: "";
}

.se-icon-logo_Weibo_circle:before {
  content: "";
}

.se-icon-logo_Weibo:before {
  content: "";
}

.se-icon-logo_Wiser:before {
  content: "";
}

.se-icon-logo_Youtube_circle:before {
  content: "";
}

.se-icon-logo_Youtube:before {
  content: "";
}

.se-icon-loudspeaker_on_1:before {
  content: "";
}

.se-icon-loudspeaker_on:before {
  content: "";
}

.se-icon-mail_forward:before {
  content: "";
}

.se-icon-mail_get_opened:before {
  content: "";
}

.se-icon-mail_new:before {
  content: "";
}

.se-icon-mail_reply:before {
  content: "";
}

.se-icon-mail_replyall:before {
  content: "";
}

.se-icon-mail_send_paper_plane_perspective:before {
  content: "";
}

.se-icon-mail_send_paper_plane:before {
  content: "";
}

.se-icon-mail_send:before {
  content: "";
}

.se-icon-mail:before {
  content: "";
}

.se-icon-maintenance_closed:before {
  content: "";
}

.se-icon-maintenance_scheduled:before {
  content: "";
}

.se-icon-man_in_motion:before {
  content: "";
}

.se-icon-man_running:before {
  content: "";
}

.se-icon-man_with_headset:before {
  content: "";
}

.se-icon-management:before {
  content: "";
}

.se-icon-manual_control:before {
  content: "";
}

.se-icon-map_location:before {
  content: "";
}

.se-icon-map:before {
  content: "";
}

.se-icon-measurement_ampmeter:before {
  content: "";
}

.se-icon-measurement_metering:before {
  content: "";
}

.se-icon-measurement_voltmeter:before {
  content: "";
}

.se-icon-media_aspect_ratio:before {
  content: "";
}

.se-icon-media_av:before {
  content: "";
}

.se-icon-media_camera:before {
  content: "";
}

.se-icon-media_closed_caption:before {
  content: "";
}

.se-icon-media_dvd:before {
  content: "";
}

.se-icon-media_eject:before {
  content: "";
}

.se-icon-media_enter:before {
  content: "";
}

.se-icon-media_gaming_controller:before {
  content: "";
}

.se-icon-media_live:before {
  content: "";
}

.se-icon-media_microphone_off:before {
  content: "";
}

.se-icon-media_microphone1:before {
  content: "";
}

.se-icon-media_microphone2:before {
  content: "";
}

.se-icon-media_music_list:before {
  content: "";
}

.se-icon-media_music:before {
  content: "";
}

.se-icon-media_photo_gallery:before {
  content: "";
}

.se-icon-media_Picture1:before {
  content: "";
}

.se-icon-media_pip:before {
  content: "";
}

.se-icon-media_player_continued:before {
  content: "";
}

.se-icon-media_player_fastrun:before {
  content: "";
}

.se-icon-media_player_next:before {
  content: "";
}

.se-icon-media_player_pause:before {
  content: "";
}

.se-icon-media_player_playrun:before {
  content: "";
}

.se-icon-media_player_previous:before {
  content: "";
}

.se-icon-media_player_repeat:before {
  content: "";
}

.se-icon-media_player_stop:before {
  content: "";
}

.se-icon-media_record:before {
  content: "";
}

.se-icon-media_swap:before {
  content: "";
}

.se-icon-media_switch:before {
  content: "";
}

.se-icon-media_tv_watching:before {
  content: "";
}

.se-icon-media_tv:before {
  content: "";
}

.se-icon-media_vhs:before {
  content: "";
}

.se-icon-media_video:before {
  content: "";
}

.se-icon-media:before {
  content: "";
}

.se-icon-meeting_request:before {
  content: "";
}

.se-icon-microscope:before {
  content: "";
}

.se-icon-mobile_connection:before {
  content: "";
}

.se-icon-module_ip:before {
  content: "";
}

.se-icon-module_ip3:before {
  content: "";
}

.se-icon-moment:before {
  content: "";
}

.se-icon-money_savings:before {
  content: "";
}

.se-icon-money:before {
  content: "";
}

.se-icon-motion_sensor:before {
  content: "";
}

.se-icon-motor:before {
  content: "";
}

.se-icon-move:before {
  content: "";
}

.se-icon-nature_flower:before {
  content: "";
}

.se-icon-nature_tree:before {
  content: "";
}

.se-icon-nature_trees:before {
  content: "";
}

.se-icon-network_draw:before {
  content: "";
}

.se-icon-new_folder:before {
  content: "";
}

.se-icon-new_project:before {
  content: "";
}

.se-icon-nfc_label:before {
  content: "";
}

.se-icon-nfc:before {
  content: "";
}

.se-icon-non_pre_integrated:before {
  content: "";
}

.se-icon-notebook_paper:before {
  content: "";
}

.se-icon-notification_critical_stroke:before {
  content: "";
}

.se-icon-notification_critical:before {
  content: "";
}

.se-icon-notification_error_full_stroke:before {
  content: "";
}

.se-icon-notification_error_plain:before {
  content: "";
}

.se-icon-notification_error:before {
  content: "";
}

.se-icon-notification_historic_critical:before {
  content: "";
}

.se-icon-notification_historic:before {
  content: "";
}

.se-icon-notification_off:before {
  content: "";
}

.se-icon-notification_ok_stroke:before {
  content: "";
}

.se-icon-notification_ok_wired:before {
  content: "";
}

.se-icon-notification_ok:before {
  content: "";
}

.se-icon-notification_on:before {
  content: "";
}

.se-icon-object_align_centre:before {
  content: "";
}

.se-icon-object_align_edge:before {
  content: "";
}

.se-icon-object_radial_gauge:before {
  content: "";
}

.se-icon-object_stretch:before {
  content: "";
}

.se-icon-onoff_off:before {
  content: "";
}

.se-icon-onoff_on:before {
  content: "";
}

.se-icon-onoff_standby:before {
  content: "";
}

.se-icon-onoff:before {
  content: "";
}

.se-icon-open_book:before {
  content: "";
}

.se-icon-orientation_horizontal:before {
  content: "";
}

.se-icon-orientation_vertical:before {
  content: "";
}

.se-icon-other_plain:before {
  content: "";
}

.se-icon-other_vertical:before {
  content: "";
}

.se-icon-other:before {
  content: "";
}

.se-icon-outdoor_lighting:before {
  content: "";
}

.se-icon-page:before {
  content: "";
}

.se-icon-pallet:before {
  content: "";
}

.se-icon-paper_stack:before {
  content: "";
}

.se-icon-pdu_rack_3_phases:before {
  content: "";
}

.se-icon-pen:before {
  content: "";
}

.se-icon-perforated_floor_tile:before {
  content: "";
}

.se-icon-perimeter:before {
  content: "";
}

.se-icon-phase_break:before {
  content: "";
}

.se-icon-phase_overcurrent_fault:before {
  content: "";
}

.se-icon-phases_3:before {
  content: "";
}

.se-icon-phone_ip:before {
  content: "";
}

.se-icon-phone:before {
  content: "";
}

.se-icon-please_wait:before {
  content: "";
}

.se-icon-plug_and_play:before {
  content: "";
}

.se-icon-power_tag:before {
  content: "";
}

.se-icon-pre_integrated:before {
  content: "";
}

.se-icon-presence_sensor_closed:before {
  content: "";
}

.se-icon-presence_sensor:before {
  content: "";
}

.se-icon-presence_simulation:before {
  content: "";
}

.se-icon-presentation_mode:before {
  content: "";
}

.se-icon-pricing_eur:before {
  content: "";
}

.se-icon-pricing_usd:before {
  content: "";
}

.se-icon-printer:before {
  content: "";
}

.se-icon-product_selector:before {
  content: "";
}

.se-icon-project_management:before {
  content: "";
}

.se-icon-project:before {
  content: "";
}

.se-icon-projection:before {
  content: "";
}

.se-icon-puzzle1:before {
  content: "";
}

.se-icon-puzzle2:before {
  content: "";
}

.se-icon-quotation:before {
  content: "";
}

.se-icon-rack_new:before {
  content: "";
}

.se-icon-rack_shelf:before {
  content: "";
}

.se-icon-rack:before {
  content: "";
}

.se-icon-rating_1star:before {
  content: "";
}

.se-icon-rating_2stars:before {
  content: "";
}

.se-icon-rating_3stars:before {
  content: "";
}

.se-icon-remote_visit:before {
  content: "";
}

.se-icon-repartitor:before {
  content: "";
}

.se-icon-report_bug:before {
  content: "";
}

.se-icon-reset:before {
  content: "";
}

.se-icon-ruler:before {
  content: "";
}

.se-icon-run:before {
  content: "";
}

.se-icon-s1:before {
  content: "";
}

.se-icon-saw:before {
  content: "";
}

.se-icon-scan_barcode:before {
  content: "";
}

.se-icon-scan_nfc:before {
  content: "";
}

.se-icon-screwdriver:before {
  content: "";
}

.se-icon-security_data_privacy:before {
  content: "";
}

.se-icon-security:before {
  content: "";
}

.se-icon-segment_airport:before {
  content: "";
}

.se-icon-segment_automotive:before {
  content: "";
}

.se-icon-segment_beer:before {
  content: "";
}

.se-icon-segment_biscuit:before {
  content: "";
}

.se-icon-segment_building_small:before {
  content: "";
}

.se-icon-segment_building:before {
  content: "";
}

.se-icon-segment_cement:before {
  content: "";
}

.se-icon-segment_datacentre:before {
  content: "";
}

.se-icon-segment_drinking_water:before {
  content: "";
}

.se-icon-segment_drinks:before {
  content: "";
}

.se-icon-segment_education:before {
  content: "";
}

.se-icon-segment_electrical_energy:before {
  content: "";
}

.se-icon-segment_electronic_surveillance_multiple:before {
  content: "";
}

.se-icon-segment_electronic_surveillance:before {
  content: "";
}

.se-icon-segment_energy_infrastructure:before {
  content: "";
}

.se-icon-segment_food_and_beverages:before {
  content: "";
}

.se-icon-segment_hoisting:before {
  content: "";
}

.se-icon-segment_hospital:before {
  content: "";
}

.se-icon-segment_hotels:before {
  content: "";
}

.se-icon-segment_hydro:before {
  content: "";
}

.se-icon-segment_industry:before {
  content: "";
}

.se-icon-segment_it:before {
  content: "";
}

.se-icon-segment_machines:before {
  content: "";
}

.se-icon-segment_marine:before {
  content: "";
}

.se-icon-segment_material_handling:before {
  content: "";
}

.se-icon-segment_medical:before {
  content: "";
}

.se-icon-segment_milk:before {
  content: "";
}

.se-icon-segment_mining_minerals_metals:before {
  content: "";
}

.se-icon-segment_mining:before {
  content: "";
}

.se-icon-segment_nuclear:before {
  content: "";
}

.se-icon-segment_og_general:before {
  content: "";
}

.se-icon-segment_og_offshore:before {
  content: "";
}

.se-icon-segment_og_onshore:before {
  content: "";
}

.se-icon-segment_og_pipeline:before {
  content: "";
}

.se-icon-segment_og_refinery:before {
  content: "";
}

.se-icon-segment_og_tankfarm:before {
  content: "";
}

.se-icon-segment_packaging:before {
  content: "";
}

.se-icon-segment_panel_builder:before {
  content: "";
}

.se-icon-segment_petfood:before {
  content: "";
}

.se-icon-segment_rail:before {
  content: "";
}

.se-icon-segment_residential:before {
  content: "";
}

.se-icon-segment_retail:before {
  content: "";
}

.se-icon-segment_textile:before {
  content: "";
}

.se-icon-segment_thermal_power:before {
  content: "";
}

.se-icon-segment_wastewater:before {
  content: "";
}

.se-icon-segment_water:before {
  content: "";
}

.se-icon-select_wiring_devices:before {
  content: "";
}

.se-icon-selection_check:before {
  content: "";
}

.se-icon-sensor2:before {
  content: "";
}

.se-icon-sensors:before {
  content: "";
}

.se-icon-shuffle:before {
  content: "";
}

.se-icon-signal_high:before {
  content: "";
}

.se-icon-signal_low:before {
  content: "";
}

.se-icon-signal_middle:before {
  content: "";
}

.se-icon-site:before {
  content: "";
}

.se-icon-sld_export:before {
  content: "";
}

.se-icon-sld:before {
  content: "";
}

.se-icon-smart_panel:before {
  content: "";
}

.se-icon-solar_irradiance1:before {
  content: "";
}

.se-icon-solar_irradiance2:before {
  content: "";
}

.se-icon-solar_irradiance3:before {
  content: "";
}

.se-icon-solar_panel1:before {
  content: "";
}

.se-icon-solar_panel2:before {
  content: "";
}

.se-icon-spim:before {
  content: "";
}

.se-icon-spinner:before {
  content: "";
}

.se-icon-sport:before {
  content: "";
}

.se-icon-spring_compressed:before {
  content: "";
}

.se-icon-spring_released:before {
  content: "";
}

.se-icon-star_plain:before {
  content: "";
}

.se-icon-star_stroke:before {
  content: "";
}

.se-icon-stretch:before {
  content: "";
}

.se-icon-success_victory:before {
  content: "";
}

.se-icon-suitcase:before {
  content: "";
}

.se-icon-switchboard_add:before {
  content: "";
}

.se-icon-switchboard_info:before {
  content: "";
}

.se-icon-system_cooling:before {
  content: "";
}

.se-icon-system_heat:before {
  content: "";
}

.se-icon-table:before {
  content: "";
}

.se-icon-tablet:before {
  content: "";
}

.se-icon-tank_fuel:before {
  content: "";
}

.se-icon-tank_heat:before {
  content: "";
}

.se-icon-task_management:before {
  content: "";
}

.se-icon-task:before {
  content: "";
}

.se-icon-temp_auto:before {
  content: "";
}

.se-icon-temperature_cold:before {
  content: "";
}

.se-icon-temperature_hot:before {
  content: "";
}

.se-icon-temperature_profile:before {
  content: "";
}

.se-icon-temperature1:before {
  content: "";
}

.se-icon-temperature2:before {
  content: "";
}

.se-icon-test_results_nok:before {
  content: "";
}

.se-icon-test:before {
  content: "";
}

.se-icon-thermal_loads:before {
  content: "";
}

.se-icon-thermostat:before {
  content: "";
}

.se-icon-thumb_down:before {
  content: "";
}

.se-icon-thumb_up:before {
  content: "";
}

.se-icon-time_chronometer:before {
  content: "";
}

.se-icon-time_saving:before {
  content: "";
}

.se-icon-timer_programmable:before {
  content: "";
}

.se-icon-timer_switch:before {
  content: "";
}

.se-icon-timer:before {
  content: "";
}

.se-icon-toggle_switch:before {
  content: "";
}

.se-icon-toggle:before {
  content: "";
}

.se-icon-tools:before {
  content: "";
}

.se-icon-towel_dryer2:before {
  content: "";
}

.se-icon-umbrella:before {
  content: "";
}

.se-icon-ups:before {
  content: "";
}

.se-icon-user_add_group:before {
  content: "";
}

.se-icon-user_add:before {
  content: "";
}

.se-icon-user_contractor:before {
  content: "";
}

.se-icon-user_distributor:before {
  content: "";
}

.se-icon-user_electrician:before {
  content: "";
}

.se-icon-user_group:before {
  content: "";
}

.se-icon-user_invite:before {
  content: "";
}

.se-icon-user_management:before {
  content: "";
}

.se-icon-user_none:before {
  content: "";
}

.se-icon-user_oem:before {
  content: "";
}

.se-icon-user_phone_contact:before {
  content: "";
}

.se-icon-user_registration_authentication:before {
  content: "";
}

.se-icon-user_remove_group:before {
  content: "";
}

.se-icon-user_remove:before {
  content: "";
}

.se-icon-user_standard:before {
  content: "";
}

.se-icon-user_systemintegrator:before {
  content: "";
}

.se-icon-validate:before {
  content: "";
}

.se-icon-venitian_shutter_closed:before {
  content: "";
}

.se-icon-venitian_shutter_middle:before {
  content: "";
}

.se-icon-venitian_shutter_open:before {
  content: "";
}

.se-icon-ventilation_high:before {
  content: "";
}

.se-icon-ventilation_slow:before {
  content: "";
}

.se-icon-vmc:before {
  content: "";
}

.se-icon-vmc2:before {
  content: "";
}

.se-icon-water_drop_closed:before {
  content: "";
}

.se-icon-weather_blizzard:before {
  content: "";
}

.se-icon-weather_clear_night:before {
  content: "";
}

.se-icon-weather_cloudy:before {
  content: "";
}

.se-icon-weather_drizzle:before {
  content: "";
}

.se-icon-weather_dust:before {
  content: "";
}

.se-icon-weather_fog:before {
  content: "";
}

.se-icon-weather_freezing_rain:before {
  content: "";
}

.se-icon-weather_haze:before {
  content: "";
}

.se-icon-weather_heavy_rain_showers:before {
  content: "";
}

.se-icon-weather_heavy_rain:before {
  content: "";
}

.se-icon-weather_light_rain_showers:before {
  content: "";
}

.se-icon-weather_light_rain:before {
  content: "";
}

.se-icon-weather_mostly_sunny:before {
  content: "";
}

.se-icon-weather_partly_cloudy:before {
  content: "";
}

.se-icon-weather_rain_snow_mix:before {
  content: "";
}

.se-icon-weather_rainy:before {
  content: "";
}

.se-icon-weather_sleet_or_freezing_drizzlle:before {
  content: "";
}

.se-icon-weather_sleet_snow_mix:before {
  content: "";
}

.se-icon-weather_smoke:before {
  content: "";
}

.se-icon-weather_snow_flurries:before {
  content: "";
}

.se-icon-weather_snow_showers:before {
  content: "";
}

.se-icon-weather_snow:before {
  content: "";
}

.se-icon-weather_snowy:before {
  content: "";
}

.se-icon-weather_sunny:before {
  content: "";
}

.se-icon-weather_sunrise:before {
  content: "";
}

.se-icon-weather_sunset:before {
  content: "";
}

.se-icon-weather_thunderstorms:before {
  content: "";
}

.se-icon-weather_thundery_showers:before {
  content: "";
}

.se-icon-weather_very_hot_and_humid:before {
  content: "";
}

.se-icon-weather_windy:before {
  content: "";
}

.se-icon-web_sdk:before {
  content: "";
}

.se-icon-webview:before {
  content: "";
}

.se-icon-weight:before {
  content: "";
}

.se-icon-width:before {
  content: "";
}

.se-icon-wifi_nosignal:before {
  content: "";
}

.se-icon-wifi_signal0:before {
  content: "";
}

.se-icon-wifi_signal100:before {
  content: "";
}

.se-icon-wifi_signal25:before {
  content: "";
}

.se-icon-wifi_signal75:before {
  content: "";
}

.se-icon-wind_speed_high:before {
  content: "";
}

.se-icon-wind_speed_low:before {
  content: "";
}

.se-icon-wind_turbine:before {
  content: "";
}

.se-icon-windfarm:before {
  content: "";
}

.se-icon-window_canvas:before {
  content: "";
}

.se-icon-window_cascade:before {
  content: "";
}

.se-icon-window_column_multi:before {
  content: "";
}

.se-icon-window_column_single:before {
  content: "";
}

.se-icon-window_dock_panel:before {
  content: "";
}

.se-icon-window_fullscreen:before {
  content: "";
}

.se-icon-window_grid_uniform:before {
  content: "";
}

.se-icon-window_grid:before {
  content: "";
}

.se-icon-window_tiles:before {
  content: "";
}

.se-icon-wrench:before {
  content: "";
}

.icon-style {
  cursor: pointer;
}

@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  src: local("Nunito Regular"), local("Nunito-Regular"), url("../src/assets/fonts/Nunito-Regular.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
  font-display: swap;
}
/** Font common accross the application end **/
/* Overlay of sidebar */
.ng-sidebar__backdrop {
  background: #747272 !important;
}

/* Right Sidebar style */
.oi-bell {
  font-size: 20px;
  vertical-align: center;
  top: 4px;
}

.right-sidebar-list {
  color: white;
  position: absolute;
}

.ng-sidebar {
  z-index: 9999999 !important;
}

.top-submenu a:hover {
  color: green;
  /* ----Start - Remove this css effect, if you don't need this animation---ITS out of current requirement :) --- 
  -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    -webkit-transition: all 3s ease;
    -moz-transition: all 3s ease;
    -o-transition: all 3s ease;
    -ms-transition: all 3s ease;
    transition: all 3s ease;
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    transform: rotate(720deg);

    ----Ends - Remove this css effect, if you don't need this animation---ITS out of current requirement :) --- */
}

.top-submenu a:focus {
  color: black;
}

.top-submenu .dropdown-item.active,
.top-submenu .dropdown-item:active {
  color: green;
  text-decoration: none;
  background-color: white;
}

/* End- of the right sidbar style */
/* You can add global styles to this file, and also import other style files */
.main-container {
  background-color: #f2f4f8;
  /* padding: 0px 12.5px !important; */
}

html,
body {
  background-color: #f2f4f8;
  font-family: "Nunito", sans-serif;
}

.menu-contianer {
  background-color: #3dcd58;
  color: white;
}

.menu-contianer a {
  color: white;
  line-height: 0.8;
  font-size: 14px;
  text-decoration: none;
}

.footer-container {
  padding: 2.5rem 0;
  text-align: center;
  background-color: #f2f4f8;
  border-top: 0rem solid #e5e5e5;
  color: #777;
  text-decoration: none;
}

.header-container {
  min-height: 54px;
  background-color: white;
}

.right-nav-icon-div {
  background-color: gray !important;
  width: 41px !important;
  text-align: right !important;
}

.nav-scroller {
  position: relative;
  z-index: 0;
  height: 30px;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.nav-scroller .nav-link {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 0.875rem;
}

#dropdownMenuButton:focus {
  border: none;
  outline: none;
}

.modal-content {
  border: 0px;
  border-radius: 8px;
  box-shadow: 4px 4px 10px #535353;
}

.modal-header {
  height: 44px;
  font-size: 18px;
  color: #ffffff;
  background-color: #3dcd58;
  border-radius: 0rem;
  align-items: center;
}

.modal-title {
  line-height: 0;
  font-size: 18px;
  font-weight: 400;
}

.close {
  color: #ffffff;
  opacity: 1;
  font-weight: 400;
}

.modal-header .close {
  padding: 0rem;
  margin: 0rem;
}

.modal-body {
  padding: 1rem 1rem 0rem 1rem;
}

.modal-footer {
  height: 44px;
}

.modal-cust-btn {
  width: 100px;
  height: 25px;
  font-size: 12px;
  background-color: #ededed;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: #9fa0a4;
  border-radius: 3px;
  padding: 0rem;
}

.modal-cust-btn-1 {
  width: 100px;
  height: 25px;
  font-size: 12px;
  background-color: #ededed;
  border-width: 1px;
  border-style: solid;
  border-color: #9fa0a4;
  border-radius: 3px;
  padding: 0rem;
}

.modal-cust-btn-dashboard {
  width: 44%;
  height: 22px;
  font-size: 12px;
  background-color: #ededed;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: #9fa0a4;
  border-radius: 3px;
  padding: 0rem;
  margin-top: 4px;
}

.modal-cust-btn:hover {
  border-color: #87d200;
}

.retry {
  background-color: transparent !important;
  font-size: 12px !important;
  text-decoration: underline !important;
  color: #0087cd !important;
  cursor: pointer;
  font-family: "Nunito-Regular", "Nunito";
  font-weight: 400;
  font-style: normal;
  font-size: 22px !important;
}

.user-msg {
  font-family: "Nunito-Regular", "Nunito";
  font-weight: 400;
  font-style: normal;
  font-size: 22px;
  color: #999999;
  text-align: center;
  text-justify: inter-word;
}

/* date category */
.nav-pills {
  flex-wrap: nowrap !important;
}

#dt-f1 {
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

#dt-f4 {
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
}

#dt-f2,
#dt-f3 {
  border-radius: 0px !important;
}

#dt-f1,
#dt-f2,
#dt-f3,
#dt-f4 {
  font-size: 11px;
  color: #626469;
  background-color: #f2f2f2;
  padding: 0.35rem 1.5rem;
  border: 1px solid #9fa0a4;
  line-height: 0.75rem;
}

#dt-f1.active,
#dt-f2.active,
#dt-f3.active,
#dt-f4.active,
#dt-f1.active:hover,
#dt-f2.active:hover,
#dt-f3.active:hover,
#dt-f4.active:hover {
  color: #ffffff;
  background-color: #626469;
}

#dt-f1:hover,
#dt-f2:hover,
#dt-f3:hover,
#dt-f4:hover {
  background-color: #e6e6e6;
}

.compExport {
  /* background: url("../src/assets/img/icons/A12-SE-Next.png"); */
  background-size: contain;
  background-repeat: no-repeat;
  height: 20px;
  width: 20px;
  /* color: $primary-color; */
  display: flex;
  /* vertical-align: middle; */
  cursor: pointer;
  margin-left: 74%;
  margin-top: -26%;
}

.compALWRExport {
  /* background: url("../src/assets/img/icons/A12-SE-Next.png"); */
  background-size: contain;
  background-repeat: no-repeat;
  height: 20px;
  width: 20px;
  position: absolute;
  left: 1em;
  top: 0.7em;
  /* color: $primary-color; */
  /* display: flex; */
  /* vertical-align: middle; */
  cursor: pointer;
  /* margin-left: 140%;
  margin-top: 18%; */
}

.compExport-simple {
  margin-top: 0% !important;
}

.deleteFromDashboard {
  /* background: url("../src/assets/img/icons/delete.png"); */
  background-size: contain;
  background-repeat: no-repeat;
  height: 20px;
  width: 20px;
  color: #3dcd58;
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  right: 8px;
  top: 5px;
  cursor: pointer;
  z-index: 999;
}

.download-pdf {
  /* background: url("../src/assets/img/icons/download-pdf.png") no-repeat center center; */
  background-size: contain;
  display: inline-block;
  background-color: transparent;
  border: none;
  height: 32px;
  width: 32px;
}

.download-png {
  /* background: url("../src/assets/img/icons/download-png.png") no-repeat center center; */
  background-size: contain;
  display: inline-block;
  background-color: transparent;
  border: none;
  height: 32px;
  width: 32px;
}

.download-csv {
  /* background: url("../src/assets/img/icons/downlaod-excel.png") no-repeat center center; */
  background-size: contain;
  display: inline-block;
  background-color: transparent;
  border: none;
  height: 32px;
  width: 32px;
}

.download-csvdata {
  /* background: url("../src/assets/img/icons/downlaod-csv.png") no-repeat center center; */
  background-size: contain;
  display: inline-block;
  background-color: transparent;
  border: none;
  height: 32px;
  width: 32px;
}

.user-info-img {
  width: 20px;
  height: 20px;
  /* background: url("../src/assets/img/power-flow/Info_icon.png") no-repeat center center; */
  background-size: 20px 20px;
  margin-right: 0.5rem;
}

.user-info-text {
  color: #42b4e6;
  font-size: 13px;
  margin: 0px;
  padding: 10px 0px;
}

@media all and (max-width: 490px) and (min-height: 490px) {
  .ci2-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    padding-bottom: 1rem;
    margin-top: -1px;
    /* overflow-x: auto; */
    text-align: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }
}
.left-sidebar aside {
  z-index: 99999999 !important;
}

.global-custom-class {
  position: absolute;
  top: 20%;
}

@media (min-width: 576px) {
  .local-reset.modal-dialog {
    max-width: 300px;
    background-color: #4290cf;
  }
}
.my-custom-class .tooltip-inner {
  background-color: #3dcd58;
  font-size: 100%;
}

/* .my-custom-class .arrow::before {
  border-top-color: $primary-color;
} */
.my-custom-class {
  z-index: 10000 !important;
}

.my-custom-class-1 .tooltip-inner {
  background-color: #3dcd58;
  font-size: 100% !important;
  /* font-weight: 7px !important; */
  /* width: 11em !important; */
}

/* .my-custom-class-1 .arrow::before {
  border-top-color: $primary-color;
} */
.my-custom-class-1 {
  z-index: 10000 !important;
}

.my-custom-class-time .tooltip-inner {
  background-color: #3dcd58;
  font-size: 100% !important;
  /* width: 25em !important; */
}

/* .my-custom-class-time .arrow::before {
  border-top-color: $primary-color;
} */
.my-custom-class-time .arrow::before {
  z-index: 10000 !important;
}

.my-custom-class-bottom .tooltip-inner {
  background-color: #3dcd58;
  font-size: 100% !important;
  /* width: 11em !important; */
}

/* .my-custom-class-bottom .arrow::before {
  border-top-color: $primary-color;
} */
.my-custom-class-bottom {
  z-index: 10000 !important;
}

::ng-deep .carousel-control-next-icon {
  color: #ccc;
}

.carousel-control-next-icon-device-detail {
  right: -15px !important;
}

.carousel-control-prev-icon-device-detail {
  left: -15px !important;
}

.carousel-control-next-icon-lifetime-energy {
  right: -5px !important;
}

.carousel-control-prev-icon-lifetime-energy {
  left: -5px !important;
}

.disablePopUp {
  pointer-events: none;
}

.schneider-green {
  color: #3dcd58;
}

#reportStyle {
  padding: 2%;
}

/* .redDot {
  height: 25px;
  width: 25px;
  background-color: #ff0000;
  border-radius: 50%;
  display: inline-block;
}

.greenDot {
  height: 25px;
  width: 25px;
  background-color: $primary-color;
  border-radius: 50%;
  display: inline-block;
} */
.redDot {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  font-size: 11px;
  color: #fff;
  line-height: 25px;
  text-align: center;
  background: #dd4e4e;
  margin: auto;
}

.greenDot {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  font-size: 11px;
  margin: auto;
  color: #fff;
  line-height: 25px;
  text-align: center;
  background: #3dcd58;
}

.selectedChart {
  cursor: pointer;
  margin-top: 0;
  font-size: 1.1rem;
  text-align: inherit !important;
  margin-right: 25%;
}

.hyperlink-blue {
  color: #0087cd !important;
}

/* background-image: url(~src/assets/img/powerFlow/line_to_left.png);
  width: 13%;
  position: absolute;
  top: 90%;
  left: 67.5%;
  height: 2%;
   */
.brandName {
  vertical-align: sub;
}

.conextBrandName {
  font-family: "Arial Rounded MT Bold", "Helvetica Rounded", Arial, sans-serif;
  font-weight: bold;
  color: #3dcd58;
}

.cloudBrandName {
  font-family: "Arial Rounded MT", "Helvetica Rounded", Arial, sans-serif;
  color: #3dcd58;
}

.hyperLink-cursor-style {
  cursor: pointer;
}

@media only screen and (min-width: 550px) and (max-width: 768px) {
  .redDot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    font-size: 5px;
    color: #fff;
    line-height: 18px;
    text-align: center;
    background: #dd4e4e;
    margin: auto;
  }

  .greenDot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    font-size: 5px;
    margin: auto;
    color: #fff;
    line-height: 18px;
    text-align: center;
    background: #3dcd58;
  }

  .compExport {
    margin-left: 100% !important;
    margin-top: -98% !important;
  }
}
/* mobiles (landscape and portrait) */
@media only screen and (min-width: 320px) and (max-width: 549px) {
  .redDot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    font-size: 5px;
    color: #fff;
    line-height: 18px;
    text-align: center;
    background: #dd4e4e;
    margin: auto;
  }

  .greenDot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    font-size: 5px;
    margin: auto;
    color: #fff;
    line-height: 18px;
    text-align: center;
    background: #3dcd58;
  }

  .compExport {
    margin-left: 100% !important;
    margin-top: -98% !important;
  }
}
@media only screen and (max-width: 320px) {
  .redDot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    font-size: 5px;
    color: #fff;
    line-height: 18px;
    text-align: center;
    background: #dd4e4e;
    margin: auto;
  }

  .greenDot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    font-size: 5px;
    margin: auto;
    color: #fff;
    line-height: 18px;
    text-align: center;
    background: #3dcd58;
  }

  .compExport {
    margin-left: 100% !important;
    margin-top: -98% !important;
  }
}
/* .drop-position {
    width: 100%;
    }

    .dropdwon-menu {
    min-width: 3.5rem;
    text-align: center;
    }

    .dropdown-color {
        color: $primary-color;
        border-color: $primary-color;
    }

    .dropdown-download {
        color: $primary-color;
    }

    .btn-outline-primary.dropdown-toggle {
        color: #fff;
        background-color: $primary-color;
        border-color: $primary-color;
    }

    dropdown-fontsize {
        font-size: 13px;
        color: $primary-color;
    }

    .dropdown-menu-padding {
        padding: 0 0;
    }
     */
/* @media only screen and (min-width: 761px) and (max-width: 768px) {
      .rightContainer,
      .leftContainer {
          margin: 12px;
          display: block;
          background: #ffffff;
          flex: 0 0 0;
          height: 100%;
      }

    } */
/* @media only screen and (min-width: 550px) and (max-width: 760px) {
      .drop-position {
        width: 17%;
      }
    }

    /* mobiles (landscape and portrait) */
/* @media only screen and (min-width: 320px) and (max-width: 549px) {
      .drop-position {
        width: 17%;
      }
    } */
/* @media only screen and (max-width: 320px) {
      .drop-position {
        width: 17%;
      }
    }  */
.download-button-tip .tooltip-inner {
  background-color: #3dcd58;
  font-size: 100%;
}

@media screen and (min-width: 200px) and (max-width: 480px) {
  .download-button-tip .tooltip-inner {
    display: none !important;
  }

  .download-button-tip {
    display: none !important;
  }

  .mycustomclass-datepicker .tooltip-inner {
    display: none;
  }

  .my-custom-class-datepicker .tooltip-inner {
    display: none;
  }

  .my-custom-class-datepicker {
    display: none;
  }
}
/* .download-button-tip .arrow::before {
  border-top-color: $primary-color;
} */
.download-button-tip {
  z-index: 10000 !important;
}

.dropdown-button-color {
  background: none !important;
  /* border-color: $primary-color; */
  color: #3dcd58 !important;
}

.show > .btn-outline-primary.dropdown-toggle {
  border-color: #3dcd58 !important;
}

.dropdown-menu-custom {
  text-align: center;
  font-size: 0.8rem;
  min-width: 3.5rem;
}

.dropdown-text-color {
  color: #3dcd58;
}

.drop-position {
  width: 100%;
}

@media only screen and (min-width: 761px) and (max-width: 768px) {
  .drop-position {
    width: 17% !important;
  }
}
@media only screen and (min-width: 550px) and (max-width: 760px) {
  .drop-position {
    width: 17% !important;
  }
}
/* mobiles (landscape and portrait) */
@media only screen and (min-width: 320px) and (max-width: 549px) {
  .drop-position {
    width: 17% !important;
  }
}
@media only screen and (max-width: 320px) {
  .drop-position {
    width: 17% !important;
  }
}
.trendLineChartToggleCss {
  position: absolute;
  right: 3.2em;
  top: 0.3em;
}

.addToDashboardCss {
  position: absolute;
  top: 1em;
  right: 6em;
}

.clickToDownloadCss {
  position: absolute;
  right: 0.9em;
  top: 0em;
}

/* .addToDashboardStyle {
  position: absolute;
  top: 1em;
  right: 6em;
}

.trendLineStyle {
  position: absolute;
  right: 4em;
  top: .3em;
} */
.clickToDownloadCssPerformance {
  position: absolute;
  right: 0.5em;
  top: 0em;
}

.clickToDownloadCssPerformanceEF {
  position: absolute;
  right: 0.5em;
  top: -0.4em;
}

.dropdwon-menu .dropdown-menu-custom {
  outline: none;
}

.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none;
}

.compESummaryExport {
  /* background: url("../src/assets/img/icons/A12-SE-Next.png"); */
  background-size: contain;
  background-repeat: no-repeat;
  height: 20px;
  width: 20px;
  /* color: $primary-color; */
  display: -webkit-box;
  display: flex;
  /* vertical-align: middle; */
  cursor: pointer;
  /* margin-left: 107%;
  margin-top: -26%; */
}

.dropdown-toggle::after {
  display: none !important;
}

.selectCss {
  width: 268px;
  padding: 5px;
  font-size: 16px;
  line-height: 1;
  border: 0;
  border-radius: 5px;
  height: 34px;
  background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #ddd;
  -webkit-appearance: none;
  background-position-x: 244px;
}

.privacyAndCookie {
  color: #ffffff !important;
  font-size: 11px;
  text-decoration: underline;
  cursor: pointer;
}

.site-create-custom-class .tooltip-inner {
  background-color: #3dcd58 !important;
  font-size: 100% !important;
  /* max-width: 285px !important; */
}

/* .site-create-custom-class .arrow::before {
  border-top-color: rgb(61, 205, 88) !important;
} */
.site-create-custom-class {
  z-index: 10000 !important;
}

input[readonly] {
  background-color: #e6e9f0;
}

.mycustomclass-datepicker .tooltip-inner {
  background-color: #3dcd58 !important;
  font-size: 100% !important;
  /* width: 600px !important; */
  /* max-width: 1005px !important; */
}

/* .mycustomclass-datepicker .arrow::before {
  border-top-color: rgb(61, 205, 88) !important;
} */
.my-custom-class-datepicker .tooltip-inner {
  background-color: #3dcd58 !important;
  /* border: 1px black solid; */
  color: white;
  font-size: 100% !important;
}

.my-custom-class-datepicker {
  z-index: 10000 !important;
}

.seGreenColor {
  color: #3dcd58;
}

.seDarkGreen {
  color: #2e9a42;
}

.card {
  background: #ffffff;
  /* box-shadow: -10px -10px 20px #f3f8f4; */
  border: 0px;
  border-radius: 8px !important;
}

.infoIconClass {
  font-size: 16px !important;
  margin-left: 4px;
  color: #acb7b1;
}

/*Crousel universal class*/
.carousel-control-prev {
  justify-content: left !important;
  width: 3% !important;
}

.carousel-control-next {
  justify-content: flex-end !important;
  width: 3% !important;
}

.carousel-control-prev-icon {
  background-image: url("/assets/image-icons/arrow/arrow_icon.png") !important;
  transform: rotate(180deg);
  width: 10px;
  height: 20px;
}

.carousel-control-next-icon {
  background-image: url("/assets/image-icons/arrow/arrow_icon.png") !important;
  width: 10px;
  height: 20px;
}

.carousel-indicators {
  margin: 0 !important;
}

ngb-carousel:focus {
  outline: none;
}

.carousel-indicators .active {
  background-color: #2e9a42;
}

.carousel-indicators li {
  height: 4px;
  width: 4px;
  margin-bottom: 10px;
  border: 0.1rem solid #2e9a42;
  border-radius: 50%;
  display: inline-block;
}

/* Tooltip Class */
.tooltipCustomClass .tooltip-inner {
  background-color: #3dcd58;
  font-size: 100% !important;
  width: 100% !important;
}

.siteListIconAlignment {
  margin-right: 0.5rem !important;
}

.seperatorRule {
  padding: 0rem;
  margin: 0rem;
  border: 0;
  border-top: 1px solid #2e9a42;
}

.hyperlinkStyle {
  color: #4290cf;
  cursor: pointer;
}

.refreshAPIClass {
  align-items: center;
  display: flex;
  font-size: 48px;
  color: #3dcd58;
  justify-content: center;
}

.cursorPointer {
  cursor: pointer;
}

/* guage styles*/
mwl-gauge {
  width: 150px;
  height: 150px;
  display: block;
  /* padding: 10px; */
  margin: auto;
}

mwl-gauge > .gauge > .dial {
  stroke: #eee;
  stroke-width: 2;
  fill: rgba(0, 0, 0, 0);
}

mwl-gauge > .gauge > .value {
  stroke: #3dcd58;
  stroke-width: 2;
  fill: rgba(0, 0, 0, 0);
}

mwl-gauge > .gauge > .value-text {
  fill: #3dcd58;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 1em;
}

/* Let's get this party started */
/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #9fa0a4;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #9fa0a4;
}

.blink {
  animation: blink-animation 3s steps(5, start) infinite;
  -webkit-animation: blink-animation 3s steps(5, start) infinite;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
.multiSitetabClass {
  font-size: 20px;
}

.toggleFilterBtnClass {
  font-size: 12px !important;
}

.blackHR {
  background-color: black;
}

.dashboardHeader {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
  color: #626469;
}

.performance_subheader .datepickerMenu {
  top: 4px !important;
  left: 90px !important;
}

.siteRegistrationHomeCustomModalClass .modal-dialog {
  max-width: 83% !important;
}

.siteRegistrationHomeCustomModalClass .tooltip-inner {
  background-color: #3dcd58 !important;
  font-size: 100% !important;
  max-width: 285px !important;
}

.siteRegistrationHomeCustomModalClass .arrow::before {
  border-top-color: #3dcd58 !important;
}

.docsUploadModalClass .modal-dialog {
  top: -70px !important;
}

ng-sidebar > aside {
  width: 95% !important;
}

.commonSubHeaderDropDownBox {
  height: 50px !important;
}

.commonSubHeaderDropDownBoxForDocumentType {
  height: 50px !important;
  width: 20rem;
}

.commonSubHeaderDropDownBoxForDocumentMobileType {
  height: 50px !important;
  width: 14.5rem;
}

.commonSubHeaderDropDownBoxForUserType {
  height: 50px !important;
  width: 25rem;
}

.arrowStyleDropdown {
  position: absolute !important;
  left: 17.5rem;
  top: 0.5rem !important;
}

.arrowStyleUserTypeDropdown {
  position: absolute !important;
  left: 22.5rem;
  top: 0.5rem !important;
}

.commonSubHeaderDropDownBoxInEvents {
  height: 35px !important;
}

.pointer {
  cursor: pointer;
}

.reportSubHeaderDropDownBox {
  font-size: 18px !important;
}

.downloadDivLink {
  cursor: pointer;
  text-decoration: none;
}

.downloadDivLink:hover {
  text-decoration: underline;
}

.siteSelectionWidth {
  background-color: transparent;
  color: #626469;
  padding: 8px;
  display: flex;
  font-size: 16px;
  border: 1px solid transparent;
  line-height: 22px;
  font-weight: bold;
  font-style: normal;
  font-family: "Nunito";
  overflow-x: clip;
  width: 130px !important;
}